

















import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { MetricPoint, Metrics } from '@/features/core/util/metrics';
import { MetricDescriptorInput, SpotRole } from '@/types/iot-portal';
import { isDef } from '@/util/lang';
import { DateProp, StringProp } from '@/util/prop-decorators';
import { FetchResult } from 'apollo-link';
import moment from 'moment';
import { Component, Mixins } from 'vue-property-decorator';
import { AufzugheldenMetricNames } from './constants';
import latestMetricsQuery from './latest-metrics.gql';
import metricsSubscription from './metrics.gql';
import {
  DeviceRolesAufzugheldenDeviceSpotMetricsGraphWidgetMetricsSubscription,
  DeviceRolesAufzugheldenDeviceSpotMetricsGraphWidgetMetricsSubscriptionVariables,
} from './__generated__/DeviceRolesAufzugheldenDeviceSpotMetricsGraphWidgetMetricsSubscription';
import {
  DeviceRolesAfuzugheldenDeviceSpotMetricsGraphWidgetLatestMetricsPointsQuery,
  DeviceRolesAfuzugheldenDeviceSpotMetricsGraphWidgetLatestMetricsPointsQueryVariables,
} from './__generated__/DeviceRolesAfuzugheldenDeviceSpotMetricsGraphWidgetLatestMetricsPointsQuery';

@Component({
  apollo: {
    latestMetricsPoints: {
      query: latestMetricsQuery,
      skip(): boolean {
        return this.stopDate === undefined;
      },
      variables(
        this: AufzugheldenDeviceSpotGraphWidget,
      ): DeviceRolesAfuzugheldenDeviceSpotMetricsGraphWidgetLatestMetricsPointsQueryVariables {
        return { spotId: this.spotId, ...this.descriptorDates };
      },
      manual: true,
      result(
        this: AufzugheldenDeviceSpotGraphWidget,
        { data }: FetchResult<DeviceRolesAfuzugheldenDeviceSpotMetricsGraphWidgetLatestMetricsPointsQuery>,
      ) {
        this.latestMetrics = Metrics.create(data?.spots.first.metrics.map(({ latest }) => latest) ?? []);
      },
    },
    $subscribe: {
      metrics: {
        query: metricsSubscription,
        variables(
          this: AufzugheldenDeviceSpotGraphWidget,
        ): DeviceRolesAufzugheldenDeviceSpotMetricsGraphWidgetMetricsSubscriptionVariables {
          return { spotId: this.spotId };
        },
        result(
          this: AufzugheldenDeviceSpotGraphWidget,
          { data }: FetchResult<DeviceRolesAufzugheldenDeviceSpotMetricsGraphWidgetMetricsSubscription>,
        ) {
          if (data) {
            this.metrics.pushLatest(data.point);
          }
        },
      },
    },
  },
  data() {
    return {
      liveMetrics: new Metrics(),
      latestMetrics: new Metrics(),
    };
  },
})
export default class AufzugheldenDeviceSpotGraphWidget extends Mixins(DeviceRoleMapMixin) {
  @StringProp()
  private readonly spotId!: string;

  @DateProp()
  private readonly startDate?: Date;

  @DateProp()
  private readonly stopDate?: Date;

  private liveMetrics!: Metrics;
  private latestMetrics!: Metrics;

  private get metrics(): Metrics {
    return this.stopDate === undefined ? this.liveMetrics : this.latestMetrics;
  }

  private get descriptorDates(): Pick<MetricDescriptorInput, 'start' | 'stop'> {
    const start = moment(this.startDate).startOf('day').toDate();
    const stop = this.stopDate === undefined ? undefined : moment(this.stopDate).add(1, 'day').startOf('day').toDate();

    return { start, stop };
  }

  private get metricRows(): MetricPoint[] {
    return Object.values(this.metrics.all).filter(isDef);
  }

  private get aufzugheldenMetrics(): MetricPoint[] {
    const importantMetricNames = this.deviceRoleMap[SpotRole.AUFZUGHELDEN_DEVICE].importantMetricNames ?? [];

    return this.metricRows.filter(({ name }) => importantMetricNames.includes(name));
  }

  private get aufzugheldenLiftState(): MetricPoint['value'] | undefined {
    return this.metricRows.find(({ name }) => name === AufzugheldenMetricNames.LiftState)?.value;
  }

  private get aufzugheldenLiftRuns(): MetricPoint['value'] | undefined {
    return this.aufzugheldenMetrics.find(({ name }) => name === AufzugheldenMetricNames.LiftRuns)?.value;
  }

  private get aufzugheldenDoorCycles(): MetricPoint['value'] | undefined {
    return this.aufzugheldenMetrics.find(({ name }) => name === AufzugheldenMetricNames.DoorCycles)?.value;
  }
}
